.pointer {
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.form-hint {
    color: #b5b5b5;
    font-size: 70%;
}

.tag.light-primary {
    background: #5fe0cd;
    color: #fff;
}

.tag.light-info {
    background: #7dc4f3;
    color: #fff;
}

.tag.light-danger {
    background: #fb6262d1;
    color: #fff;
}

.tag.light-gray {
    background: #858484;
    color: #fff;
}

/* TOOLTIP */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 10rem;
    background-color: black;
    color: #fff;
    font-size: 0.8em;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

/* TABLE RESPONSIVE */
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
	/* Force table to not be like tables anymore */
	.responsive.table, .responsive.table > thead, .responsive.table tbody,
    .responsive.table th, .responsive.table td, .responsive.table tr {
		display: block;
	}

    .responsive.table thead {
        display: none;
    }

	.responsive.table tr { border: 1px solid #ccc; margin-bottom: 1rem; }

	.responsive.table td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding: 0.5em 0.75em 0.5em  40% !important;
        min-height: 2.65rem;
	}

    .responsive.table td.skeleton {
        padding: 0.5em 0.75em !important;
    }

	.responsive.table td:before {
		/* Now like a table header */
		position: absolute;
		top: 0.5em;
		left: 0.6em;
		width: 40%;
		padding-right: 10px;
        white-space: nowrap;
		overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        content: attr(data-label);
	}
}

li.is-active {
    color: #cd451f;
    border-bottom-color: #cd451f;
}

.button.is-link.is-outlined.is-inverted:hover {
    color: #cd451f !important;
}